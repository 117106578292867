import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, Subject } from 'rxjs';
import { NavigationPath } from 'src/app/shared/class/navigation-path';
import { ConfirmBox } from 'src/app/shared/class/confirm-box';
import { ErrorBox } from 'src/app/shared/class/error-box';
import { UserMessages } from 'src/app/shared/class/user-messages';
import { AddWBAScreen } from '../../error-message/addprospect-screen';
import { WhiteBoardActivityService } from '../../services/white-board-activity.service';
import { KeyboardValidation } from 'src/app/shared/services/caffeineValidators/keyboard-validation';
import { CustomeAmountPipe } from 'src/app/shared/pipes/amount.pipe';
import { CustomeRemoveAmountPipe } from 'src/app/shared/pipes/remove-amount.pipe';
import { RemoveWhiteSpacesPipe } from 'src/app/shared/pipes/remove-white-spaces.pipe';
import { LookupProspectComponent } from './lookup-prospect/lookup-prospect.component';
import { LookupUsersComponent } from './lookup-users/lookup-users.component';
import { LookupLeadGeneratorComponent } from './lookup-lead-generator/lookup-lead-generator.component';
import { LookupCarrierComponent } from './lookup-carrier/lookup-carrier.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WBANotifierService } from '../../services/wba-notifier.service';
import { RegxConstant } from 'src/app/shared/class/regx';
import { DatePipe } from '@angular/common';
import { CustomNotifireService } from 'src/app/shared/services/view-prospect-notifire.service';
import { JsonHubProtocol } from '@microsoft/signalr';
import { ProspectListService } from 'src/app/features/prospect/services/prospect-list.service';
import { CarriersService } from 'src/app/features/carrier-management/services/carriers-service';
import { PreventBackButtonService } from 'src/app/shared/services/prevent-back-button.service';
import { ProfileInfoService } from 'src/app/shared/services/profile-info.service';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { AddprospectService } from 'src/app/features/prospect/services/addprospect.service';
import { LookupManagerComponent } from './lookup-manager/lookup-manager.component';
import { Title } from '@angular/platform-browser';
import { DatacacheService } from '../../services/datacache.service';
import { MasterDatacacheService } from '../../services/masterdatacache.service';

@Component({
  selector: 'app-add-whiteboard',
  templateUrl: './add-whiteboard.component.html',
  styleUrls: ['./add-whiteboard.component.css']
})
export class AddWhiteboardComponent implements OnInit, OnDestroy {
  isFormClosedfromCross: boolean = false;
  isFormClosedfromCross1: boolean = false;
  @Input() fromWBAList: any;
  selectedData: any
  lookupData: any;
  //  wb: any;
  invalidowner: any;
  invalidLeadGerator: any;
  whiteBoardForm: any;
  selectedCompanyName = "";
  selectedEffectiveDate: any;
  selectedLineOfBusiness = "";
  isDisabled: boolean = false;

  isContactInfo: boolean = false;
  isSubmitted: boolean = false;
  _AddWhiteBoardScreen: AddWBAScreen;
  value: any = "";
  //selectedAccount: any;
  //keyword = 'name';
  accountList: any;
  _confirmBox: ConfirmBox;
  _warningBox1: ConfirmBox;
  _errorBox: ErrorBox;
  _userMessages: UserMessages;
  userList: any = []
  businessLine: any = []
  businessStatus: any = []
  _navigationPath: NavigationPath;
  prospectId: any = 0;
  isCheck: boolean = false;
  _regxConstant: RegxConstant;
  showaddressChoice: boolean = true;
  selectedWhiteboardName: string = '';
  WBAPriority: any = []
  prospectIndustryList: any = []
  prospectTierList: any = []
  expRevenue: any;
  targetRevenue: any;
  result: any;
  expiringCarrierlist: any;
  leadGenratorlist: any;
  ownerlist: any;
  selectedItem: any;
  lookupcomponent: any;
  leadGeneratorValue: any;
  bussinesslinevalue: any;
  showError: boolean = false;
  errorMessage: any;
  errorList: any[] = [];
  //checkprospect: any;
  contactlist: any;
  selectedValue: any = "";
  selectedPolicyTypeValue: any = "";
  selectedTierValue: any = "";
  selectedIndustryValue: any = "";
  finalExpValue: any;
  office1Ext: any;
  office2Ext: any;
  office3Ext: any;
  contactExisted: boolean = false;
  createNodeData: any;
  SelectedWbaPriorityName: string = '';
  profileDetails: any = { name: '', id: 0 }
  pageNo: number = 1;
  public pageSize = 10;
  public totalCount = 0;
  masterProspectList: any[] = [];
  masterCarrierList: any[] = [];
  policyTypeList: any;
  disabled: boolean = true
  unAthorizesd: any = '';
  currentYear: any;
  selectedYear: any;
  serviceResponse$!: Observable<any>;
  unsubscribe$: Subject<boolean> = new Subject();
  prospectDeatils: any;
  invalid: boolean = false;
  invalidExpringCarrier: boolean = false;
  invalidCompanyName: boolean = false;
  specialityIdForPolicyType: any;
  showPolicyTypeStar: boolean = false;
  isTierAlreadyDefinedFromProspect: boolean = false;
  lookupFilterData: string = '';
  carrierLookupFilterData: string = '';
  LeadlookupFilterData: string = '';
  ownerlookupFilterData: string = '';
  systemTierId: any;
  Selectedvalue: any;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private _customNotifireService: CustomNotifireService,
    private _addWBAService: WhiteBoardActivityService,
    private _ProspectListService: ProspectListService,
    private _carriersService: CarriersService,
    private router: Router,
    private route: ActivatedRoute,
    public ValidateKey: KeyboardValidation,
    public _amountPipe: CustomeAmountPipe,
    private _removeAmountPipe: CustomeRemoveAmountPipe,
    private _removeWhiteSpacesPipe: RemoveWhiteSpacesPipe,
    private _notifierService: WBANotifierService,
    private _datepipe: DatePipe,
    private _preventBack: PreventBackButtonService,
    public profileInfoService: ProfileInfoService,
    private _AddprospectService: AddprospectService,
    private titleService: Title,
    private _dataCacheService: MasterDatacacheService
  ) {
    this._AddWhiteBoardScreen = new AddWBAScreen();
    this._confirmBox = new ConfirmBox();
    this._warningBox1 = new ConfirmBox();
    this._errorBox = new ErrorBox();
    this._userMessages = new UserMessages();
    this._navigationPath = new NavigationPath();
    this._regxConstant = new RegxConstant();
  }

  ngOnInit(): void {
    this.titleService.setTitle('Add Whiteboard Activity')
    sessionStorage.setItem('isFormDirty', '')
    this.route.queryParams
      .subscribe(params => {
        this.prospectId = params.prospectId
      });

    this.profileInfoService.statusCode.subscribe((res: any) => {
      this.unAthorizesd = res ? res : '';
    })
    this._preventBack.preventBackButton();
    this.getFormControl();
    this.whiteBoardForm.get('policyTypeId').disable();
    // this.getBusinessStatus();
    this.getWBAPriorityList();
    // this.getBusinessLineList();
    this.initializedData();

    this.getProspectIndustryList();
    this.getProspectTierList();
    this.getleadgenretor('leadGenerator', 'Cold');
    //below code works when we come from view prospect then it fills prospect name
    if (this.prospectId) {
      this.addWbaFromProspectDetails()
    }

    this.getResponse();
    if (localStorage.getItem("userDetails") !== null) {
      this.ownerlist = JSON.parse(localStorage.getItem("userDetails") || '{name: "", id: ""}');
      this.whiteBoardForm.controls['ownerName'].setValue({
        id: this.ownerlist.user.userId,
        value: this.ownerlist.user.display_name
      });
      this.ownerlookupFilterData = this.ownerlist.user.display_name;
    }
  }

  initializedData() {
    this.businessStatus = this._dataCacheService.retrieveData('wba_businessStatusList');
    this.businessLine = this._dataCacheService.retrieveData('wba_businessLine');
    this.policyTypeList = this._dataCacheService.retrieveData('wba_policyTypeList');
    if (this.businessStatus.length == 0 || this.businessLine.length == 0) this.getAllMaster();
    else this.mapAddWba();
  }
  mapAddWba() {
    this.currentYear = `New Client ${new Date().getFullYear()}`;
    this.selectedYear = this.businessStatus.businessStatusList.find((el: any) => el.businessStatusName == this.currentYear)
    this.whiteBoardForm.get('businessStatusId').setValue(this.selectedYear.businessStatusId);
  }
  getAllMaster() {
    var businessStatus = this._addWBAService.getBusinessStatusList();
    var busniessLine = this._addWBAService.getBusinessLineList();
    var policytypesList = this._addWBAService.getPolicyTypeList(5)
    forkJoin([
      businessStatus, busniessLine, policytypesList
    ]).subscribe((resp) => {
      if (resp) {
        this.businessStatus = resp[0];
        this.businessLine = resp[1];
        this.policyTypeList = resp[2].businessLineList
        this._dataCacheService.compressAndCacheData('wba_businessStatusList', this.businessStatus);
        this._dataCacheService.compressAndCacheData('wba_businessLine', this.businessLine);
        this._dataCacheService.compressAndCacheData('wba_policyTypeList', this.policyTypeList);
        this.mapAddWba();
      }
    },

      (err: any) => {
        this.errorList = []
        this.showError = true;
        if (err.status == 403) {
          this.showError = true;
          this.errorList.push(this.unAthorizesd)
          return
        }
        if (err.status == 400) {
          this.errorMessage = "Error : " + err.error.errors.ProspectId[0];
          this.errorList.push(this.errorMessage);
        }
        if (err.status == 500) {
          this.errorMessage = "Error : " + err.message + ", Status: " + err.status;;
          this.errorList.push(this.errorMessage);
        }
      });
  }

  addWbaFromProspectDetails() {
    //this._notifierService.prospectData.subscribe((res: any) => {
    // if (res) {
    //   if (res.id) this.prospectId = res.id;
    //   this.whiteBoardForm.controls['companyName'].patchValue({
    //     id: res.id,
    //     prospectName: res.prospectName
    //   });
    //   this.lookupFilterData = res.prospectName;
    // }
    //  else {
    // if (this.prospectId) {
    //   this._AddprospectService.getProspectById(this.prospectId).subscribe(
    //     (resp) => {
    //       if (resp && resp.prospectInfo) {
    //         this.prospectDeatils = resp.prospectInfo;
    //         this.lookupFilterData = this.prospectDeatils.prospectName
    //         this.whiteBoardForm.controls['companyName'].patchValue({
    //           id: this.prospectDeatils.id,
    //           prospectName: this.prospectDeatils.prospectName
    //         });
    //       }
    //     },
    //     (error) => {
    //       let obj = error.error.errors;
    //       if (obj) {
    //         var arr = Object.keys(obj).map((key) => ({
    //           type: key,
    //           value: obj[key],
    //         }));
    //         this.errorList = arr;
    //       }
    //     }
    //   );
    // }
    //}
    this.getProspectDetail(this.prospectId);
    this.whiteBoardForm.get('prospectId').setValue(this.prospectId);
    this.changeWhiteboardName("companyName");
    this._customNotifireService.setActiveComponent('Whiteboard');

    //})
  }

  getFormControl() {
    this.whiteBoardForm = this._formBuilder.group({
      companyName: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(60)]],
      prospectId: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(60)]],
      targetPremium: [{ value: null, disabled: this.isDisabled }, [Validators.required]],
      businessStatusId: [{ value: '', disabled: this.isDisabled }, [Validators.required]],
      businessLineId: [{ value: null, disabled: this.isDisabled }, Validators.required],
      policyTypeId: [{ value: '', disabled: this.isDisabled }],
      industryId: [{ value: null, disabled: this.isDisabled }, Validators.required],
      tierId: [{ value: '', disabled: this.isDisabled }, Validators.required],
      effectiveDate: [{ value: '', disabled: this.isDisabled }, Validators.required],
      contactId: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(60)]],
      priorityId: [{ value: '', disabled: this.isDisabled }, [Validators.required]],
      leadGenerator: [{ value: '', disabled: this.isDisabled }, [Validators.required]],
      expiringCarrier: [{ value: '', disabled: this.isDisabled }],
      expiringPremium: [{ value: '', disabled: this.isDisabled }],
      expiringRevenue: [{ value: '', disabled: this.isDisabled }],
      targetRevenue: [{ value: null, disabled: this.isDisabled }, [Validators.required]],
      ownerName: [{ value: '' }, [Validators.required]],
      ein: [{ value: '', disabled: this.isDisabled }, [Validators.required]],
      profitCenter: ['Lamb'],
      isExisting: ['true'],
      contact: this._formBuilder.group({
        id: [{ value: '', disabled: this.isDisabled }, [Validators.maxLength(60)]],
        prospectId: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(100)]],
        firstName: [{ value: '', disabled: this.isDisabled }, [Validators.required, Validators.maxLength(200)]],
        lastName: [{ value: '', disabled: this.isDisabled }, [Validators.maxLength(200)]],
        jobTitle: [{ value: '', disabled: this.isDisabled }, [Validators.maxLength(200)]],
        phoneNumber: [{ value: '', disabled: this.isDisabled }, [Validators.required]],
        extension: [{ value: '', disabled: this.isDisabled }],
        emailAddress: [{ value: '', disabled: this.isDisabled }, [Validators.maxLength(100), Validators.pattern(this._regxConstant.Email())]],
        isPrimary: ['false'],
      })
    })
  }
  getResponse() {
    this._notifierService.getValue().subscribe(res => {
      if (res) {
      }
    })
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem("prospectName");
  }

  classAppliedLeft = true;
  classAppliedRight = true;
  btnclass2 = true;
  toggleClassLeft() {
    this.classAppliedLeft = !this.classAppliedLeft;
    this.classAppliedRight = !this.classAppliedRight;
    this.btnclass2 = !this.btnclass2;
  }

  closeError() {
    this.showError = false;
  }
  get f() {
    return this.whiteBoardForm.controls;
  }

  get FirstName(): any {
    return this.whiteBoardForm.get('contact.firstName');
  }

  get LastName(): any {
    return this.whiteBoardForm.get('contact.lastName');
  }

  get JobTitle(): any {
    return this.whiteBoardForm.get('contact.jobTitle');
  }

  get PhoneNumber(): any {
    return this.whiteBoardForm.get('contact.phoneNumber');
  }

  get Extension(): any {
    return this.whiteBoardForm.get('contact.extension');
  }

  get EmailAddress(): any {
    return this.whiteBoardForm.get('contact.emailAddress');
  }

  clearValue() {
    this.whiteBoardForm.get('prospectId').setValue(0);
    this.invalidCompanyName = false;
    this.isSubmitted = false;
  }

  setValidator() {
    this.whiteBoardForm.get('expiringCarrier')?.setValidators([Validators.required]);
    this.whiteBoardForm.get('expiringCarrier')?.updateValueAndValidity();
  }

  saveWBA(type: any) {
    this.isSubmitted = true;
    // let isWBABelongs2Umbrella = this.businessLine.businessLineList.find((x: any) => x.businessLineId == this.whiteBoardForm.controls.businessLineId.value.businessLineId);
    // if (!isWBABelongs2Umbrella.newBusiness) {
    //   this._warningBox1.message = '"Umbrella" is not a valid line, please select "Package" in order to create an "Umbrella" policy.';
    //   this._warningBox1.isVisible = true;
    //   return
    // }
    if (this.whiteBoardForm.controls.ein.errors?.mask?.actualValue == "0") {
      this.whiteBoardForm.controls.ein.status = 'VALID'
    }
    if (
      this.whiteBoardForm.get('expiringCarrier')?.value ||
      this.whiteBoardForm.get('expiringPremium')?.value
    ) {
      this.setValidator();
    } else {
      this.whiteBoardForm.get('expiringCarrier')?.setValidators(null);
      this.whiteBoardForm.get('expiringCarrier')?.updateValueAndValidity();
    }
    console.log("111", this.whiteBoardForm.value)
    if (this.whiteBoardForm.value.businessLineId.businessLineId !== 1) {
      this.whiteBoardForm.get('ein')?.setValidators(null);
      this.whiteBoardForm.get('ein')?.updateValueAndValidity();
    }
    if (this.whiteBoardForm && this.whiteBoardForm.valid) {
      let data = this.whiteBoardForm.value;
      this.invalidowner = {
        id: data.ownerName.id ? data.ownerName.id : '',
        value: data.ownerName.value ? data.ownerName.value : ''
      };
      if (!data.ownerName.id) {
        return
      }
      this.invalidLeadGerator = {
        id: data.leadGenerator.id ? data.leadGenerator.id : '',
        value: data.leadGenerator.value ? data.leadGenerator.value : ''
      };
      if (!data.leadGenerator.id) {
        return
      }
      if (this.invalidExpringCarrier == true || this.invalidCompanyName == true) {
        return;
      }
      let saveData = {
        "prospectId": data.prospectId,
        "targetPremium": data.targetPremium ? parseFloat(this._removeAmountPipe.transform(data.targetPremium)) : 0,
        "masterBusinessStatusId": data.businessStatusId,
        "masterBusinessLineId": data.businessLineId.businessLineId,
        "industryId": data.industryId ? data.industryId : 0,
        "prospectSizeId": parseInt(this.selectedTierValue), //data.tierId ? data.tierId : 0,
        "systemTierId": this.systemTierId,
        "effectiveDate": this._datepipe.transform(data.effectiveDate, 'yyyy-MM-dd'),
        "masterWBAPriorityId": data.priorityId,
        "expiringPremium": data.expiringPremium ? parseFloat(this._removeAmountPipe.transform(data.expiringPremium)) : 0,
        "expiringRevenue": data.expiringRevenue ? parseFloat(this._removeAmountPipe.transform(data.expiringRevenue)) : 0,
        "expectedBoundRevenue": data.targetRevenue ? parseFloat(this._removeAmountPipe.transform(data.targetRevenue)) : 0,
        "OwnerUserId": data.ownerName.id,
        "masterLeadGeneratorId": data.leadGenerator.id,
        "ein": data.ein == 0 ? "" : data.ein.toString(),
        "masterExpiringCarrierId": data.expiringCarrier.id ? data.expiringCarrier.id : 0,
        "contactId": data.isExisting == 'true' ? data.contactId : 0,
        "isExisting": data.isExisting == 'true' ? true : false,
        "profitCenter": data.profitCenter ? data.profitCenter : "",
        "contact": {
          "id": data.isExisting == 'true' ? data.contactId : 0,
          "prospectId": data.prospectId,
          "firstName": data.contact.firstName,
          "lastName": data.contact.lastName,
          "jobTitle": data.contact.jobTitle,
          "Office1PhoneNumber": data.contact.phoneNumber,
          "office1Extension": data.contact.extension ? data.contact.extension : "",
          "office1Email": data.contact.emailAddress,
        },
        "policyTypeid": data.policyTypeId
      };
      if (saveData.isExisting == true && saveData.contact.Office1PhoneNumber == undefined) {
        if (this.result.contact) {
          saveData.contact.firstName = this.result.contact.firstName,
            saveData.contact.lastName = this.result.contact.lastName,
            saveData.contact.jobTitle = this.result.contact.jobTitle,
            saveData.contact.Office1PhoneNumber = this.result.contact.office1PhoneNumber,
            saveData.contact.office1Extension = this.result.contact.office1Extension,
            saveData.contact.office1Email = this.result.contact.office1Email,
            saveData.contact.prospectId = this.result.contact.prospectId
          //})
        }
      }

      this._addWBAService.saveWBActivity(saveData).subscribe(resp => {
        if (resp) {
          this.isSubmitted = false;
          this.isFormClosedfromCross = true;
          //updating total count zero in storage
          let setTotalCountZero = JSON.parse(sessionStorage.getItem('wbaFilterStorage')!)
          if (setTotalCountZero) {
            setTotalCountZero.totalCount = setTotalCountZero.totalCount + 1
            sessionStorage.setItem('wbaFilterStorage', JSON.stringify(setTotalCountZero));
          }
          if (type == 'close' && sessionStorage.getItem("component") === "WhiteboardsComponent") {
            this.router.navigate(['/whiteboard-activities']);
          }
          else if (type == 'close') {
            this.router.navigate(['/' + this._navigationPath.getWhiteboardsUrl()])
          }
          else if (type == 'next') {
            this._notifierService.setValue(resp);
            this.router.navigate(['/' + this._navigationPath.getWhiteboardsUrl() + '/' + this._navigationPath.getwhiteboardsApplicationUrl()], { queryParams: { wbaId: resp, listId: 0, prospectId: this.prospectId } });
          }
          sessionStorage.removeItem("component");

          this.createNodeData = { wbaId: resp };
          this.createNode(this.createNodeData);
          this.CreateEpicClient(this.createNodeData);
        }
      },
        (err: any) => {
          this.errorList = []
          this.showError = true;
          if (err.status == 403) {
            this.showError = true;
            this.errorList.push(this.unAthorizesd)
            return
          }
          if (err.status == 400) {
            this.errorMessage = "Error : " + err.error.errors.ProspectId[0];
            this.errorList.push(this.errorMessage);
          }
          if (err.status == 500) {
            this.errorMessage = "Error : " + err.message;
            this.errorList.push(this.errorMessage);
          }
        });
    }
  }
  createNode(data: any) {
    this._addWBAService.createNode(data).subscribe((resp: any) => {
      if (resp) {
      }
    })
  }
  CreateEpicClient(data: any) {
    this._addWBAService.CreateEpicClient(data).subscribe((resp: any) => {
      if (resp) {
      }
    })
  }

  lookUpDataFilterChanges(val: any) {
    if (val == 'companyName') {
      this.lookupFilterData = "";
    }
    if (val == 'Carrier') {
      this.carrierLookupFilterData = "";
    }
    if (val == 'leadGenerator') {
      this.LeadlookupFilterData = "";
    }
    if (val == 'Owner') {
      this.ownerlookupFilterData = "";
    }
  }

  getCompanyList(fieldName: any, val: any) {
    if (fieldName == 'companyName') {
      this.lookupFilterData = val.query;
    }
    else if (fieldName == 'Carrier') {
      this.carrierLookupFilterData = val.query;
    }
    else if (fieldName == 'leadGenerator') {
      this.LeadlookupFilterData = val.query;
    }

    if (val.query.length > 1) {
      if (fieldName && val.query) {
        let encode = val.query.replaceAll('&', '%26')
        this._addWBAService.getTypeheadList(fieldName, encode).subscribe(resp => {
          if (fieldName == 'companyName') {
            this.accountList = resp.prospectList;
            this.invalidCompanyName = true
          }
          else if (fieldName == 'Carrier') {
            this.invalidExpringCarrier = true
            this.expiringCarrierlist = resp.expiringCarrierList;
          }
          else if (fieldName == 'leadGenerator') {

            this.leadGenratorlist = resp.leadGeneratorList;
            this.invalidLeadGerator = {
              id: '',
              value: ''
            }
          }

        })
      }
    }
  }

  getleadgenretor(field: any, value: any) {
    if (field && value) {
      this._addWBAService.getTypeheadList(field, value).subscribe(resp => {
        this.leadGenratorlist = resp.leadGeneratorList;
        const selectedItem = resp.leadGeneratorList[0];
        this.whiteBoardForm.get('leadGenerator').setValue({
          id: selectedItem.id,
          name: selectedItem.name
        })
        this.LeadlookupFilterData = selectedItem.name;
      })
    }
  }

  getOwnerlist(result: any) {
    this.invalidowner = {
      id: '',
      value: ''
    };
    let data = {
      searchType: "Owner",
      value: result.query
    };
    this.ownerlookupFilterData = result.query;
    this._addWBAService.getOwnerList(data).subscribe(resp => {
      this.ownerlist = resp.userList;
    })
  }

  getContactByName(checkName: any) {
    this._addWBAService.getContactByName(checkName.query, this.prospectId).subscribe(resp => {
      this.contactlist = resp.contactList;
    })
  }

  checkaddressChoice(val: any) {
    this.showaddressChoice = val;
    // this.whiteBoardForm.get('isExisting').setValue(val);
    if (this.result !== undefined) {
      if (val.value === "false") {
        this.contactExisted = true;
        this.whiteBoardForm.get('contact').reset();
        this.whiteBoardForm.get('contact').enable();
        if (this.result.contact?.prospectId) {
          this.whiteBoardForm.get('contact').patchValue({
            prospectId: this.result.contact.prospectId
          })
        }
      }
      else if (val.value === "true" && this.result.contact) {
        this.whiteBoardForm.get('contact').disable();
        this.whiteBoardForm.get('contact.firstName').enable();
        this.contactExisted = false;
        if (this.result.contact.office1Extension) {
          this.office3Ext = this.result.contact.office1Extension ? this.result.contact.office1Extension : null;
          this.whiteBoardForm.get('contact.extension').setValue(this.office3Ext);
        }
        else {
          this.whiteBoardForm.get('contact.extension').setValue('');
        }

        this.whiteBoardForm.get('contact').patchValue({
          firstName: this.result.contact.firstName,
          lastName: this.result.contact.lastName,
          jobTitle: this.result.contact.jobTitle,
          phoneNumber: this.result.contact.office1PhoneNumber,
          emailAddress: this.result.contact.office1Email,
          prospectId: this.result.contact.prospectId
        })
      }
    } else {
      if (val.value === "false") {
        this.whiteBoardForm.get('isExisting').patchValue(val.value);
        this.whiteBoardForm.get('contact').enable();
        this.contactExisted = true;
      } else if (val.value === "true") {
        this.whiteBoardForm.get('isExisting').patchValue(val.value);
        this.whiteBoardForm.get('contact').disable();
        this.whiteBoardForm.get('contact.firstName').enable();
        this.contactExisted = false;
      }
    }

  }

  selectItem(item: any, type: any) {
    if (type == 'companyName') {
      if (item.prospectInformation?.ein) {
        this.whiteBoardForm.get('ein').setValue(item.prospectInformation.ein);
      }
      else {
        this.whiteBoardForm.get('ein').setValue('');
      }

      this.whiteBoardForm.get('prospectId').setValue(item?.id);
      this.invalidCompanyName = this.whiteBoardForm.get('prospectId').value != 0 ? false : true;
      this.changeWhiteboardName(type);
      var pId = item?.id;
      this.getProspectDetail(pId);
      this.prospectId = pId;
      this.lookupFilterData = item.prospectName;
    } else if (type == 'businessStatus') {
      this.whiteBoardForm.get('businessStatusId').setValue(item?.id);
    } else if (type == 'businssLine') {
      this.whiteBoardForm.get('businessLineId').setValue(item?.id);
      this.changeWhiteboardName(type);
    } else if (type == 'wbaPriorityList') {
      this.whiteBoardForm.markAsDirty();
      this.whiteBoardForm.get('priorityId').setValue(item?.id);
      this.SelectedWbaPriorityName = item?.value;
      this.whiteBoardForm.get('id').setValue(item?.id);
    }
    else if (type == 'ownerName') {

      this.whiteBoardForm.controls['ownerName'].setValue({
        id: item.userId,
        value: item.userName
      });
      this.ownerlookupFilterData = item.userName;
      this.invalidowner = this.whiteBoardForm.get('ownerName').value
    }
    else if (type == 'leadGenerator') {
      this.whiteBoardForm.get('leadGenerator').setValue({
        id: item.id,
        name: item.name
      });
      this.LeadlookupFilterData = item.name;
      this.invalidLeadGerator = this.whiteBoardForm.get('ownerName').value
    }
    else if (type == 'expiringCarrier') {
      this.whiteBoardForm.get('expiringCarrier').setValue({
        id: item.id,
        value: item.value
      });
      this.carrierLookupFilterData = item.value;
      this.invalidExpringCarrier = false;
    }
    else if (type == 'firstName') {
      this.whiteBoardForm.get('contactId').setValue(item?.id)
      if (item?.office1Extension) {
        this.office2Ext = item?.office1Extension ? item?.office1Extension : null;
        this.whiteBoardForm.get('contact.extension').setValue(this.office2Ext);
      }
      else {
        this.whiteBoardForm.get('contact.extension').setValue('');
      }

      this.whiteBoardForm.get('contact').patchValue({
        firstName: item?.firstName,
        lastName: item?.lastName,
        jobTitle: item?.jobTitle,
        phoneNumber: item?.office1PhoneNumber,
        emailAddress: item?.office1Email
      });
      this.result.contact.firstName = item?.firstName;
      this.result.contact.lastName = item?.lastName;
      this.result.contact.jobTitle = item?.jobTitle;
      this.result.contact.office1PhoneNumber = item?.office1PhoneNumber;
      this.result.contact.office1Extension = item?.office1Extension;
      this.result.contact.office1Email = item?.office1Email;
    }
  }

  changeDate(event: any) {
    this.changeWhiteboardName('effectiveDate');
  }

  //to cancel the process and back to prospect list screen
  cancel() {
    if (this.selectedData) {
      this.whiteBoardForm.markAsDirty();
    }
    if (!this.whiteBoardForm.dirty) {
      if (sessionStorage.getItem("component") === "WhiteboardsComponent") {
        this.router.navigateByUrl('/' + this._navigationPath.getProspectsUrl() + '/' + this._navigationPath.getProspectsViewUrl() + "?prospectId=" + this.prospectId);
      }
      else {
        this.router.navigate(['/' + this._navigationPath.getWhiteboardsUrl()])
      }
      sessionStorage.removeItem("component");
    } else {
      this._confirmBox.message = this._userMessages.cancelButtonMessage;
      this._confirmBox.isVisible = true;
    }
    this.isFormClosedfromCross = true;
    this.isFormClosedfromCross1 = false;
  }
  canDeactivate(): Observable<boolean> | boolean {
    if (!this.isFormClosedfromCross) {
      if (this.selectedData) {
        this.whiteBoardForm.markAsDirty();
      }
      if (this.whiteBoardForm.dirty) {
        this._confirmBox.message = this._userMessages.cancelButtonMessage;
        this._confirmBox.isVisible = true;
        this.isFormClosedfromCross1 = true
        return false
      }
      return true
    }
    return true
  }

  confirmBoxCancel() {
    if (this.isFormClosedfromCross1) {
      this.whiteBoardForm.markAsPristine()
      this.selectedData = ""
      this.isFormClosedfromCross = true
      this.router.navigateByUrl('/' + sessionStorage.getItem('setUrlOnClick'))
      sessionStorage.removeItem('setUrlOnClick')
      this.isFormClosedfromCross1 = false;
    }
    else {
      if (sessionStorage.getItem("component") === "WhiteboardsComponent") {
        this.router.navigateByUrl('/' + this._navigationPath.getProspectsUrl() + '/' + this._navigationPath.getProspectsViewUrl() + "?prospectId=" + this.prospectId);
      }
      else {
        this.router.navigate(['/' + this._navigationPath.getWhiteboardsUrl()])
      }
      sessionStorage.removeItem("component");
    }
    this._confirmBox.isVisible = false;
  }
  confirmBoxClose() {
    this._confirmBox.isVisible = false
    this.isFormClosedfromCross = false;
  }

  //Loading prospect List.
  getProspectList() {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": "asc",
      "sortCol": "prospectName",
      "searchText": "",
      "filterJson": "",
      "gridFilterJson": ""
    }
    const modalRef = this.modalService.open(LookupProspectComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });

    modalRef.closed.subscribe(resp => {
    })
    modalRef.componentInstance.lookupFilterData = this.lookupFilterData;
    modalRef.componentInstance.lookupData = this.lookupData;


    modalRef.componentInstance.messageEvent.subscribe((data: any) => {
      if (data) {
        this.whiteBoardForm.controls['companyName'].patchValue({
          id: data.id,
          prospectName: data.prospectName
        });
        this.selectedData = data.prospectName;
        this.selectItem(data, 'companyName');
        this.invalidCompanyName = false
      }
    });
  }

  //Loading prospect List.
  getCarriersListInfo(searchType: string) {
    let data = {
      "pageNumber": this.pageNo,
      "pageSize": this.pageSize,
      "totalCount": this.totalCount,
      "sortDir": "asc",
      "sortCol": "carrierShortName",
      "searchName": "",
      "userId": "",
      "filterJson": "",
      "gridFilterJson": "",
      "searchType": searchType,
    }

    const modalRef = this.modalService.open(LookupCarrierComponent, {
      keyboard: false,
      backdrop: 'static',
      modalDialogClass: 'customeDialog1'
    });

    modalRef.closed.subscribe(resp => {
    })
    modalRef.componentInstance.carrierLookupFilterData = this.carrierLookupFilterData;
    modalRef.componentInstance.lookupData = this.lookupData;
    modalRef.componentInstance.searchType = searchType;
    modalRef.componentInstance.passEntry.subscribe((resp: any) => {
      this.selectedData = resp
    }
    )

    modalRef.componentInstance.messageEvent.subscribe((data: any) => {
      if (data) {
        this.whiteBoardForm.controls['expiringCarrier'].patchValue({
          id: data.id,
          value: data.value
        });
        this.carrierLookupFilterData = data.value;
        this.invalidExpringCarrier = false
      }
    });
  }

  getUsersListPaginate() {
    var data = {
      pageNo: 1,
      pageSize: 10,
      lookUpType: "Owner"
    }
    if (data) {

      this.serviceResponse$ = this._addWBAService.lookupPagination(data);
      this.serviceResponse$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: any) => {
        if (resp) {
          this.lookupData = {
            data: [...resp.items[0].userList],
            total: resp.totalRecord
          };
          const modalRef = this.modalService.open(LookupManagerComponent, {
            keyboard: false,
            backdrop: 'static',
            modalDialogClass: 'customeDialog1'
          });

          modalRef.closed.subscribe(resp => {
          })
          modalRef.componentInstance.ownerlookupFilterData = this.ownerlookupFilterData;
          modalRef.componentInstance.lookupData = this.lookupData;
          modalRef.componentInstance.passEntry.subscribe((resp: any) => {
            this.selectedData = resp
          }
          )

          modalRef.componentInstance.messageEvent.subscribe((data: any) => {
            if (data) {
              this.whiteBoardForm.controls['ownerName'].patchValue({
                id: data.userId,
                value: data.userName
              });
              this.invalidowner = this.whiteBoardForm.get('ownerName').value;
              this.ownerlookupFilterData = data.userName;
            }
          });
        }
        else {

        }
      },
        (error: any) => {

        })
    }

  }
  openLookupModal(value: any) {
    let data = {
      "lookUpType": value,
      "rowCount": 10
    }
    if (value === "companyName") {
      this.getProspectList()
    } else if (value === 'Carrier') {
      this.getCarriersListInfo(value);
    }
    else if (value === "Owner") {
      this.getUsersListPaginate();
    }
    else {
      this._addWBAService.getWhiteBoardActivityLookUp(data).subscribe((response: any) => {
        if (response) {
          this.lookupData = response;
          if (value === "leadGenerator") {
            this.lookupcomponent = LookupLeadGeneratorComponent;
          }

          const modalRef = this.modalService.open(this.lookupcomponent, {
            keyboard: false,
            backdrop: 'static',
            modalDialogClass: 'customeDialog1'
          });
          modalRef.closed.subscribe(resp => {
          })
          modalRef.componentInstance.LeadlookupFilterData = this.LeadlookupFilterData;
          modalRef.componentInstance.lookupData = this.lookupData;
          modalRef.componentInstance.passEntry.subscribe((resp: any) => {
            this.selectedData = resp
          }
          )

          modalRef.componentInstance.messageEvent.subscribe((data: any) => {
            if (data) {
              if (value === "leadGenerator") {
                this.whiteBoardForm.controls['leadGenerator'].patchValue({
                  id: data.id,
                  name: data.name
                });
                this.LeadlookupFilterData = data.name
              }
            }
          });

        }
      }, (err: any) => {
        if (err) {
          this.showError = true;
          this.errorMessage = "Error message: " + err.message + ", Status Code: " + err.status;
        }
      });
    }
  }

  //to get business status list
  getBusinessStatus() {
    this._addWBAService.getBusinessStatusList().subscribe(resp => {
      this.businessStatus = resp
      this.currentYear = `New Client ${new Date().getFullYear()}`;
      this.selectedYear = this.businessStatus.businessStatusList.find((el: any) => el.businessStatusName == this.currentYear)
      this.whiteBoardForm.get('businessStatusId').setValue(this.selectedYear.businessStatusId)
    })
  }

  //to get user list
  getUserList() {
    this._addWBAService.getUserList().subscribe(resp => {
      this.userList = resp.userList
    })
  }

  //to get Business Line list
  getBusinessLineList() {
    this._addWBAService.getBusinessLineList().subscribe(resp => {
      this.businessLine = resp;
      // this.businessLine.businessLineList.splice(this.businessLine.businessLineList.findIndex((e: any) => e.businessLineName === "Umbrella"), 1);//removing Umbrella for NB
      this.specialityIdForPolicyType = this.businessLine.businessLineList.find((x: any) => x.businessLineName == 'Specialty').businessLineId;
      this.getPolicyTypeList();

    })
  }

  //to get policy Type list for speciality
  getPolicyTypeList() {
    this._addWBAService.getPolicyTypeList(this.specialityIdForPolicyType).subscribe(resp => {
      this.policyTypeList = resp.businessLineList
    })
  }



  navigateTo(page: any) {
    this.router.navigate(['/' + this._navigationPath.getWhiteboardsUrl() + '/' + this._navigationPath.getwhiteboardsApplicationUrl()]);
  }

  getProspectDetail(pId: any) {
    this.whiteBoardForm.get('isExisting')?.reset();
    if (pId) {
      this.errorList = [];
      this._addWBAService.getProspectById(pId, false).subscribe(resp => {
        if (resp && resp.prospectInfo) {
          var prospect = resp.prospectInfo;
          this.whiteBoardForm.controls['companyName'].patchValue({
            id: prospect.id,
            prospectName: prospect.prospectName
          });
          if (prospect?.prospectInformation?.ein) {
            this.whiteBoardForm.get('ein').setValue(prospect.prospectInformation.ein);
          }
          if (prospect.prospectInformation.industryId)
            this.selectedIndustryValue = prospect.prospectInformation.industryId;
          if (prospect.prospectInformation.prospectSizeId) {
            //this.whiteBoardForm.get('tierId').disable();
            this.isTierAlreadyDefinedFromProspect = true;
            this.selectedTierValue = prospect.prospectInformation.prospectSizeId;
          }
          this.result = prospect;
          var isExt = resp.prospectInfo;
          if (prospect.contact) {
            this.whiteBoardForm.get('contactId').setValue(prospect.contact.id);
            this.setFormValue(prospect)
          }
          else {
            this.setFormValue(prospect)
          }
        }
      });
    }
    else {
      this.errorList = [];
      this.errorList[0] = { type: '', value: 'Please select any one prospect to view details.' };
    }
  }

  setFormValue(resp: any) {
    if (resp.contact != null) {
      this.whiteBoardForm.get('isExisting').setValue('true');
      this.whiteBoardForm.get('isExisting').enable();
      this.whiteBoardForm.get('contact.firstName').enable();
      this.checkaddressChoice(true);
      this.contactExisted = false;
      this.whiteBoardForm.get('contact.prospectId').setValue(resp.contact.prospectId);
      if (resp.contact.office1Extension) {
        this.office1Ext = resp.contact?.office1Extension ? resp.contact?.office1Extension : null;
        this.whiteBoardForm.get('contact.extension').setValue(this.office1Ext);
      }
      else {
        this.whiteBoardForm.get('contact.extension').setValue('');
      }
      this.whiteBoardForm.get('contact').patchValue({
        firstName: resp.contact.firstName,
        lastName: resp.contact.lastName,
        jobTitle: resp.contact.jobTitle,
        phoneNumber: resp.contact.office1PhoneNumber,
        emailAddress: resp.contact.office1Email
      })
    } else {
      this.whiteBoardForm.get('isExisting')?.setValue('false');
      this.whiteBoardForm.get('isExisting').disable();
      this.whiteBoardForm.get('contact').reset();
      this.whiteBoardForm.get('contact').enable();
      this.contactExisted = true;
      this.checkaddressChoice(false)
      this.whiteBoardForm.patchValue({
        firstName: '',
        lastName: '',
        jobTitle: '',
        phoneNumber: '',
        extension: '',
        emailAddress: ''
      });
      this.whiteBoardForm.get('contactId').patchValue({
        contactId: this.result.contact ? this.result.contact.id : 0
      });
      this.whiteBoardForm.get('contact').patchValue({
        prospectId: this.result.id
      });
    }
  }

  // To Add currency format
  addCurrencyFormat(element: any, control?: AbstractControl) {
    var formattedAmount;
    formattedAmount = element.target.value.replace(/,/g, '');
    formattedAmount = this._amountPipe.transform(formattedAmount, 'USD', '', '1.0-0');
    element.target.value = formattedAmount;
    control?.setValue(element.target.value);
  }

  // To remove amount
  removeCurrencyFormat(element: any, val?: any) {
    var formattedAmount;
    formattedAmount = this._removeAmountPipe.transform(element.target.value);
    element.target.value = formattedAmount;
  }

  //To remove white spaces
  removeSpaces(element: any, control?: AbstractControl) {
    var removeSpaces;
    removeSpaces = this._removeWhiteSpacesPipe.transform(element.target.value, '');
    element.target.value = removeSpaces;
    control?.setValue(element.target.value);
  }

  changeWhiteboardName(type: string) {
    if (type) {
      if (type == "companyName") {
        this.selectedCompanyName = this.f.companyName.value.prospectName;
      }
      else if (type == "businssLine") {
        var SelectedLinOfBusiness = this.businessLine.businessLineList.filter((x: any) => x.businessLineId == this.f.businessLineId.value.businessLineId);
        if (SelectedLinOfBusiness && SelectedLinOfBusiness.length > 0) {
          this.selectedLineOfBusiness = SelectedLinOfBusiness[0].businessLineName;
        }
      }
      else if (type == "effectiveDate") {
        this.selectedEffectiveDate = this.f.effectiveDate.value ? this._datepipe.transform(this.f.effectiveDate.value, 'MM/dd/YYYY') : null;
      }

      if (this.selectedCompanyName && this.selectedEffectiveDate && this.selectedLineOfBusiness) {
        this.selectedWhiteboardName = this.selectedCompanyName + " " + this.selectedLineOfBusiness + " - " + this.selectedEffectiveDate;
      }
    }
  }

  //to get WBA Priority list
  getWBAPriorityList() {
    this._addWBAService.getWBAPriorityList().subscribe(resp => {
      this.WBAPriority = resp
    })
  }

  //to get Prospect Size  list
  getProspectTierList() {
    this._addWBAService.getProspectTierList().subscribe(resp => {
      this.prospectTierList = resp.prospectTierList
    })
  }


  //to get Prospect Industry list
  getProspectIndustryList() {
    this._addWBAService.getProspectIndustryList().subscribe(resp => {
      this.prospectIndustryList = resp.prospectIndustryList
    })
  }

  getExpiringRevenueCalculation(item: any, expiringPremium: any, targetPremium: any) {
    if (item.businessLineName == 'Specialty') {
      this.showPolicyTypeStar = true;
      this.whiteBoardForm.get('policyTypeId').enable();
      this.whiteBoardForm.get('policyTypeId')?.setValidators([Validators.required]);
    } else {
      this.showPolicyTypeStar = false;
      this.whiteBoardForm.get('policyTypeId').disable();
      this.whiteBoardForm.get('policyTypeId')?.setValidators(null);
      this.whiteBoardForm.get('policyTypeId').setValue("");
    }
    this.whiteBoardForm.get('policyTypeId')?.updateValueAndValidity({ emitEvent: false });

    this.changeWhiteboardName('businssLine');


    if (expiringPremium && item) {
      var expremium = this._removeAmountPipe.transform(expiringPremium);
      var expValue: any = expremium * item.businessLineValue;
      this.expRevenue = this._amountPipe.transform(parseInt(expValue.toFixed()), 'USD', '', '1.0-0')
      this.whiteBoardForm.get('expiringRevenue').setValue(expValue > 0 ? this.expRevenue : 0)
    }

    if (targetPremium && item) {
      var tarPremium = this._removeAmountPipe.transform(targetPremium);
      var expValue: any = tarPremium * item.businessLineValue;
      this.targetRevenue = this._amountPipe.transform(parseInt(expValue.toFixed()), 'USD', '', '1.0-0')
      this.whiteBoardForm.get('targetRevenue').setValue(expValue > 0 ? this.targetRevenue : 0)
      if (!this.isTierAlreadyDefinedFromProspect) {
        this.updatingTearBasedOnTargetRevenue(this._removeAmountPipe.transform(this.targetRevenue));
      }
    }
    if (targetPremium == 0 || targetPremium == "") {
      this.whiteBoardForm.get('targetRevenue').setValue("")
    }
  }

  updatingTearBasedOnTargetRevenue(tarPremium: any) {

    if (tarPremium <= 5000) {
      this.selectedTierValue = 1;
    } else if (tarPremium >= 5001 && tarPremium <= 24999) {
      this.selectedTierValue = 2;
    } else if (tarPremium >= 25000 && tarPremium <= 99999) {
      this.selectedTierValue = 3;
    } else {
      this.selectedTierValue = 4;
    }
    this.systemTierId = this.selectedTierValue;
  }
  getExtValue(extValue: any) {
    var data = extValue.toString()
    if (data.length <= 5 && data.length > 1) {
      if (data.length == 1) {
        this.finalExpValue = '0000' + data
      } else if (data.length == 2) {
        this.finalExpValue = '000' + data
      } else if (data.length == 3) {
        this.finalExpValue = '00' + data
      } else if (data.length == 4) {
        this.finalExpValue = '0' + data
      } else {
        this.finalExpValue = data
      }
    } else {
      this.finalExpValue = ''
    }
    return this.finalExpValue
  }

  typeOfEvent(event: any) {
    let value1 = event.target.value;

    if (value1 == 'Lamb') {
      this.Selectedvalue = 'Lamb'
    } else if (value1 == 'CREIS') {
      this.Selectedvalue = 'CREIS'
    } else if (value1 == 'TruePartners') {
      this.Selectedvalue = 'TruePartners'
    }

  }

}
